var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "div",
            { staticClass: "van-common-detail" },
            [
              _c("van-cell", [
                _c(
                  "div",
                  { staticClass: "goods-table" },
                  _vm._l(_vm.inWarehousingDetail.containerList, function(item) {
                    return _c(
                      "table",
                      {
                        key: item.id,
                        staticClass: "inner-goods-table",
                        attrs: { border: "1" }
                      },
                      [
                        _c("tr", [
                          _c("td", [_vm._v("柜号")]),
                          _c("td", [_vm._v(_vm._s(item.cabinetNo))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("国家")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  item.pickUpInfoVo.country,
                                  "Country"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("原产地")]),
                          _c("td", [
                            _vm._v(_vm._s(item.pickUpInfoVo.countryOfOrigin))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("HS编码")]),
                          _c("td", [_vm._v(_vm._s(item.pickUpInfoVo.hsCode))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("货物名称")]),
                          _c("td", [_vm._v(_vm._s(item.cargoName))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("箱型")]),
                          _c("td", [_vm._v(_vm._s(item.pickUpInfoVo.boxType))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("件数")]),
                          _c("td", [_vm._v(_vm._s(item.pieceNumber))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("重量（KG）")]),
                          _c("td", [
                            _vm._v(_vm._s(item.pickUpInfoVo.actualWeight))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("总金额")]),
                          _c("td", [_vm._v(_vm._s(item.money))])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          ),
          _c(
            "van-cell-group",
            { attrs: { title: "货物详情" } },
            [
              _c("van-cell", {
                attrs: {
                  title: "总价合计",
                  value: _vm.utils.moneyFormat(_vm.allUnitPriceSum.toFixed(2))
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "重量合计（KG）",
                  value: _vm.allWeightSum.toFixed(2)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "件数合计",
                  value: _vm.allPieceNumberSum.toFixed(2)
                }
              }),
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c("van-cell", [
                    _c(
                      "div",
                      { staticClass: "goods-table" },
                      _vm._l(
                        _vm.inWarehousingDetail.containerList[0].goodsList,
                        function(item, idx) {
                          return _c(
                            "table",
                            {
                              key: item.id,
                              staticClass: "inner-goods-table",
                              attrs: { border: "1" }
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "header-th",
                                    attrs: { colspan: "2" }
                                  },
                                  [_c("span", [_vm._v(_vm._s(idx + 1))])]
                                )
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("商品编号")]),
                                _c("td", [_vm._v(_vm._s(item.cargoNo))])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("品名")]),
                                _c("td", [_vm._v(_vm._s(item.productName))])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("单价")]),
                                _c("td", [_vm._v(_vm._s(item.unitPrice))])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("重量（KG）")]),
                                _c("td", [_vm._v(_vm._s(item.weight))])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("件数")]),
                                _c("td", [_vm._v(_vm._s(item.pieceNumber))])
                              ])
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }