<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <div class="van-common-detail">
        <van-cell>
          <div class="goods-table">
            <table v-for="(item) in inWarehousingDetail.containerList" :key="item.id" class="inner-goods-table" border="1">
              <tr>
                <td>柜号</td><td>{{ item.cabinetNo }}</td>
              </tr>
              <tr>
                <td>国家</td><td>{{ utils.statusFormat(item.pickUpInfoVo.country,'Country') }}</td>
              </tr>
              <tr>
                <td>原产地</td><td>{{ item.pickUpInfoVo.countryOfOrigin }}</td>
              </tr>
              <tr>
                <td>HS编码</td><td>{{ item.pickUpInfoVo.hsCode }}</td>
              </tr>
              <tr>
                <td>货物名称</td><td>{{ item.cargoName }}</td>
              </tr>
              <tr>
                <td>箱型</td><td>{{ item.pickUpInfoVo.boxType }}</td>
              </tr>
              <tr>
                <td>件数</td><td>{{ item.pieceNumber }}</td>
              </tr>
              <tr>
                <td>重量（KG）</td><td>{{ item.pickUpInfoVo.actualWeight }}</td>
              </tr>
              <tr>
                <td>总金额</td><td>{{ item.money }}</td>
              </tr>
            </table>
          </div>
        </van-cell>
      </div>
      <van-cell-group title="货物详情">
        <van-cell title="总价合计" :value="utils.moneyFormat(allUnitPriceSum.toFixed(2))" />
        <van-cell title="重量合计（KG）" :value="allWeightSum.toFixed(2)" />
        <van-cell title="件数合计" :value="allPieceNumberSum.toFixed(2)" />
        <div class="van-common-detail">
          <van-cell>
            <div class="goods-table">
              <table v-for="(item,idx) in inWarehousingDetail.containerList[0].goodsList" :key="item.id" class="inner-goods-table" border="1">
                <tr>
                  <th colspan="2" class="header-th">
                    <span>{{ idx+1 }}</span>
                  </th>
                </tr>
                <tr>
                  <td>商品编号</td><td>{{ item.cargoNo }}</td>
                </tr>
                <tr>
                  <td>品名</td><td>{{ item.productName }}</td>
                </tr>
                <tr>
                  <td>单价</td><td>{{ item.unitPrice }}</td>
                </tr>
                <tr>
                  <td>重量（KG）</td><td>{{ item.weight }}</td>
                </tr>
                <tr>
                  <td>件数</td><td>{{ item.pieceNumber }}</td>
                </tr>
              </table>
            </div>
          </van-cell>
        </div>
      </van-cell-group>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
// import fileList from '@/components/file-list'
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      inWarehousingDetail: {},
      quotaDetail: {},
      qualificationList: [],
      businessLicenseList: [],
      fileBusinessSnImgsList: [],
      accountInfoVoList: [],
      allUnitPriceSum: 0,
      allWeightSum: 0,
      allPieceNumberSum: 0
    }
  },
  created () {
    this.getInWarehousingDetail()
  },
  methods: {
    // 处理文件数组
    getFileName (fileUrl) {
      const splitList = fileUrl ? fileUrl.split('/') : []
      return splitList[splitList.length - 1] || ''
    },
    getInWarehousingDetail () {
      this.allUnitPriceSum = 0
      this.allWeightSum = 0
      this.allPieceNumberSum = 0
      this.utils.loading()
      this.api.task.inWarehousing.getDetail(this.$route.query.detailId).then(result => {
        this.inWarehousingDetail = result.data.value
        this.inWarehousingDetail.containerList[0].goodsList.forEach(item => {
          this.allUnitPriceSum = this.allUnitPriceSum + ((item.unitPrice * 1) * (item.weight * 1000) / 1000)
          this.allWeightSum = ((this.allWeightSum * 1000) + (item.weight * 1000)) / 1000
          this.allPieceNumberSum = ((this.allPieceNumberSum * 1000) + (item.pieceNumber * 1000)) / 1000
        })
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    preview (file, detail) {
      if (file.fileType) {
        const fileTypeArr = file.fileType.split('/')
        if (fileTypeArr[0] !== 'image') {
          window.open(file.url)
        }
      }
    }
  }
}
</script>

<style scoped>
  .certificate-photo >>> .van-uploader__wrapper {
    display: inline-flex !important;
    flex-wrap: inherit !important;
  }
  .van-common-detail >>> .van-tabs .van-tabs__wrap .van-tabs__nav:first-child .van-tab{
    padding: 0 !important;
  }
</style>
